<template>
  <section
    v-element-visibility="scrolledIntoView"
   
  >
    <h3 class="mb-2">
      {{ t(`${translationPrefix}.title`) }}
    </h3>
    <p>
      {{
        t(`${translationPrefix}.content`, {
          start: formatAsShortDateLong(bookingEstimateParams.dateStart),
          end: formatAsShortDateLong(bookingEstimateParams.dateEnd),
        })
      }}
    </p>
    <div class="calendar-wrapper">
      <Transition name="fade">
        <LazySearchDatesForm
          v-if="showDateRange"
          :dates="{ start: bookingEstimateParams.dateStart, end: bookingEstimateParams.dateEnd }"
          :disabled-dates="disabledDates"
          :unavailable-days="rvUnavailableDays"
          :min-date="rvMinBookableDate"
          :max-date="rvMaxBookableDate"
          :timezone="rvComputedTimezone"
          show-clear-button
          data-test-id-suffix="availability"
          @update:dates="(value) => updateDates(value)"
        />
        <div
          v-else
          class="skel"
        />
      </Transition>
    </div>

    <div
      v-if="isBookable"
      class="popular-dates"
    >
      {{ t('popularDates') }}
      <EstimateButton
       
        size="sm"
        inline-button
      />
    </div>

    <EstimateErrorTipbox class="mt-3" />
  </section>
</template>

<script setup lang="ts">
import { vElementVisibility } from '@vueuse/components'
import type { SearchDates } from '~/types/search'

const { t } = useI18n()

const showDateRange = ref(false)

const {
  rvBookingSeasonStart,
  rvBookingSeasonEnd,
  rvCalendars,
  rvComputedTimezone,
  rvMinBookableDate,
  rvMaxBookableDate,
  rvRVSellingDate,
  rvUnavailableDays,
} = useRvDetails()

const disabledDates = computed(() => {
  return [
    ...getRvCalendarBlockedRangesFromSellingDate(rvRVSellingDate.value),
    ...getRvCalendarBlockedRangesFromBookingSeason(rvBookingSeasonStart.value, rvBookingSeasonEnd.value),
    ...getRvCalendarBlockedDates(rvCalendars.value),
  ]
})

const { bookingEstimateParams, isBookable } = useBookingEstimate()

const translationPrefix = computed(() => {
  if (isBookable.value) {
    return 'available'
  }

  return 'check'
})

function scrolledIntoView(isVisible: boolean) {
  if (isVisible) {
    showDateRange.value = true
  }
}

const { $search } = useNuxtApp()
const { updateDateRange } = useBookingEstimate()
const route = useRoute()
async function updateDates(dates: SearchDates['dates']) {
  updateDateRange({
    dateStart: dates.start || null,
    dateEnd: dates.end || null,
  })

  $search.updateDates(dates)
  await $search.updateRoute(route.path)
}
</script>

<style lang="scss" scoped>
.calendar-wrapper {
  position: relative;
  width: 100%;
  min-height: 300px;
}

.skel {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
}

:deep() {
  .calendars-container {
    max-width: none;

    .vc-pane-layout {
      grid-gap: 2rem;
    }

    .vc-day {
      @include media-min-size(xLarge) {
        min-height: 3rem;
      }
    }
  }
}

.popular-dates {
  background-color: getColor('highlight-75');
  border-radius: 0.5rem;
  padding: 0.75rem;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 1rem;
  @include caption;

  @include media-min-size(medium) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  button {
    flex-shrink: 0;
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "check": {
      "title": "Check availability",
      "content": "Select your trip dates"
    },
    "available": {
      "title": "Available for your dates",
      "content": "{start} - {end}"
    },
    "popularDates": "These dates are popular! Request to book while they’re still free."
  },
  "fr": {
    "check": {
      "title": "Vérifier la disponibilité",
      "content": "Sélectionnez vos dates de voyage"
    },
    "available": {
      "title": "Disponible pour vos dates",
      "content": "{start} - {end}"
    },
    "popularDates": "Ces dates sont populaires! Demandez de les réserver tant qu’elles sont encore disponbles."
  }
}
</i18n>
